export default class PlayOnHover {
  constructor(dataAttrName = "[data-play-on-hover]") {
    const videosNodeList = document.querySelectorAll(dataAttrName);

    if (!videosNodeList) {
      console.info("[PlayOnHover] No videos detected on page");
      return;
    }

    if (window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      console.info(
        "`prefers-reduced-motion` detected. Video autoplay on hover disabled.",
      );
      return;
    }

    try {
      videosNodeList.forEach((videoEl) => {
        const innerVideo =
          videoEl.nodeName != "VIDEO"
            ? videoEl.querySelector("video")
            : videoEl;

        const linkWrapper = videoEl.closest("a");

        if (innerVideo.nodeName == "VIDEO") {
          const isLoop = videoEl.hasAttribute("loop");
          linkWrapper.addEventListener("mouseover", (e) => {
            if (!isLoop) {
              innerVideo.currentTime = 0; // Start from the beginning
            }

            innerVideo.play();
            innerVideo.addEventListener(
              "ended",
              () => {
                innerVideo.pause(); // Stop on the last frame
              },
              { once: true },
            );
          });
          linkWrapper.addEventListener("mouseout", (e) => {
            innerVideo.pause();
            if (!isLoop) {
              innerVideo.currentTime = 0; // Start from the beginning
            }
          });
        } else {
          console.error(
            "[PlayOnHover] Video wrapper detected, but no <video> element found",
          );
        }
      });
    } catch {
      console.warn(
        "[PlayOnHover] Something somewhere should have been done differently",
      );
    }
  }
}
